<template>
  <div class="footer-container">
    <div class="footer-view">
      <div class="footer-view-top">
        <div class="footer-view-top-left">
          <div class="footer-logo">
            <img src="@/assets/images/main/logo-white.png" alt="">
          </div>
          <div class="footer-text">
            <p>{{ $t('highquality') }}</p>
          </div>
          <div class="theme-view">
            <p v-if="!value">{{ $t('bright') }}</p>
            <p v-if="value">{{ $t('dark') }}</p>
            <el-switch
                @change="changeTheme"
                v-model="value"
                active-color="#3875FB"
                inactive-color="#3875FB">
            </el-switch>
          </div>

        </div>
        <div class="footer-view-top-right">
          <div class="qrcode-view">
            <img src="@/assets/images/main/qrcode-wechat.png" alt="">
            <p>{{ $t('WeChatAccount') }}</p>
          </div>
          <div class="qrcode-view">
            <img src="@/assets/images/main/qrcode-weibo.png" alt="">
            <p>{{ $t('weibo') }}</p>
          </div>
        </div>
      </div>
      <div class="footer-view-bottom">
        <div class="footer-option">
          <p @click="showAgreement(5)">{{ $t('aboutus') }}</p>
          <p @click="showAgreement(1)" class="p-left">{{ $t('useragreement') }}</p>
          <p @click="showAgreement(6)" class="p-left">{{ $t('privacypolicy') }}</p>
          <p @click="showAgreement(7)" class="p-left">{{$t('contactus') }}</p>
        </div>
        <a href="https://beian.miit.gov.cn/" target="_blank" class="put-on-records">{{$t('ICP') }}</a>
      </div>
    </div>
	<!-- 协议弹窗 -->
	<AgreementPopup ref="popupAgreement"></AgreementPopup>
  </div>
</template>

<script>
import {setTheme} from "@/assets/theme";
import {getTheme} from "@/utils/common";
import AgreementPopup from "@/components/popup/AgreementPopup.vue"

export default {
  name: "Footer",
  data() {
    return {
      value: getTheme() == 'dark',
	  agreeType: 1
    }
  },
  components: {
	  AgreementPopup
  },
  methods: {
    getTheme,
    changeTheme() {
      if (this.getTheme() == "dark") {
        setTheme("default");
      } else {
        setTheme("dark");
      }
    },
	showAgreement(type) {
	  console.log('协议')
	  this.agreeType = type
	  this.$refs.popupAgreement.open()
	  this.$refs.popupAgreement.getAgreement(this.agreeType)
	},
  }
}
</script>

<style scoped lang="less">
.footer-container {
  width: 100%;
  height: 306px;
  background: @footerBackgroundColor;

  .footer-view {
    width: 62.5%;
    min-width: 1100PX;
    height: 306px;
    margin: auto;
    position: relative;

    &-top {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;

      &-left {
        width: 50%;
        height: 164px;

        .footer-logo {
          width: 185px;
          height: 63px;

          img {
            width: 100%;
          }
        }

        .footer-text {
          margin-top: 25px;

          p {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 33px;
			white-space: nowrap;
          }
        }

        .theme-view {
          display: flex;
          margin-top: 16px;

          p {
            color: #fff;
            margin-top: 2px;
          }

          .el-switch {
            margin-left: 10px;
          }
        }
      }

      &-right {
        width: 50%;
        height: 164px;
        display: flex;
        justify-content: right;

        .qrcode-view {
          width: 130px;
          height: 160px;
          margin-right: 40px;

          &:last-child {
            margin-right: 0px;
          }

          p {
            height: 30px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            text-align: center;
          }

          img {
            width: 130px;
            height: 130px;
          }
        }
      }


    }

    &-bottom {
      margin-top: 10px;

      .footer-option {
        display: flex;
        height: 36px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        .p-left {
          margin-left: 44px !important;
        }

        p {
          cursor: pointer;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.5);
          line-height: 17px;

          &:hover {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }

      .put-on-records {
        margin-top: 20px;
        cursor: pointer;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 17px;
        text-decoration: none;

        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
</style>