<template>
  <div class="main">
    <header class="header-view">
      <MusicHeader/>
    </header>
    <main class="main-view">
      <router-view/>
    </main>

    <footer class="footer-view">
      <MusicPlayer class="player-view" v-if="$store.state.playShow == true"/>
      <MusicFooter :class=" $store.state.playShow == true ? 'footer-padding' : ''"/>
    </footer>
  </div>
</template>

<script>
import MusicHeader from "../components/main/Header.vue"
import MusicFooter from "@/components/main/Footer";
import MusicPlayer from "@/components/main/MusicPlayer";
import {myInfo} from "@/api/Mine";
import {setMyInfo, getMyInfo,} from "@/utils/common/user";
import {getToken, removeToken} from "@/utils/common";

export default {
  props: {},
  data() {
    return {
      // num:'',
      // type:'',
      // dropdownList:[]
    }
  },
  components: {MusicHeader, MusicFooter, MusicPlayer},
  computed: {},
  created() {
  },
  mounted() {
    if (this.getToken()) {
      myInfo().then((res) => {
        this.$store.state.loginname = res.data.name;
        this.$store.state.userImage = res.data.image;
        this.$store.state.introduce = res.data.introduce
        // this.$store.state.isProve = res.data.is_prove
        this.$store.state.identity = res.data.identity
        this.$store.state.countryId = res.data.countryId
        console.log('存入222')
        this.setMyInfo(res.data)
        // console.log(this.getMyInfo(),'getMyInfo111111')
        // console.log(this.$store.state.loginname,'myInfo')
      });
    }
  },
  watch: {},
  methods: {
    getToken,
    setMyInfo
  }
}
</script>

<style scoped lang='less'>
.main {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh);

  .header-view {
    width: 100%;
    height: 78px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 40;
    flex: 0 0 auto;
  }

  .main-view {
    padding-top: 98px;
	/* padding-top: 24px; */
    padding-bottom: 20px;
    width: 62.5%;
    min-width: 1100PX;
    height: auto;
    margin: 0 auto;
    flex: 1 1 auto;
  }

  .player-view {
    width: 100%;
    height: 90px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    background: @musicPlayerBgColor;
	box-shadow: 0px -10px 10px 0px rgba(207, 204, 193, 0.1)
  }

  .footer-view {
    width: 100%;
    height: 306px;
    flex: 0 0 auto;
  }

  .footer-padding {
    padding-bottom: 120px;
  }
}
</style>
